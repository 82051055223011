import actionTypes from "src/utils/actionTypes";

const defaultState = {
  subscriptions: {
    apple: [],
    google: [],
    facebook: [],
    memberMouse: [],
    zMaster1Active: [],
    zMaster2Inactive: [],
  },
  noOfUsers: 0,
  noOfProducts: 0,
  noOfSubstances: 0,
  noOfManufacturers: 0,
  loginData: [],
  topGoals: {},
  levelOne1GoalsArr: [],
  levelOne2GoalsArr: [],
  levelOneOtherGoalsArr: [],
  levelTwo1GoalsArr: [],
  levelTwo2GoalsArr: [],
  levelTwoOtherGoalsArr: [],
  levelOneNames: [],
  users: [],
  goalsCSV: [],
  selectedLanguage: {
    name: "Hungarian",
    code: "hu",
  },
};

const dashboardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_SUCCCESS:
    case actionTypes.GET_DASHBOARD_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.GET_LOGIN_DATA_SUCCCESS:
    case actionTypes.GET_LOGIN_DATA_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.GET_SELECTED_GOALS_SUCCESS:
    case actionTypes.GET_SELECTED_GOALS_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
