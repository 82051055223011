import actionTypes from "src/utils/actionTypes";

const defaultState = {
  leve3: null,
  isLoading: [],
};

const subAdminReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_LEVEL3_SUCCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.GET_LEVEL3_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.SIGNOUT_SUCCESS:
      return {
        state: defaultState,
      };

    case actionTypes.SIGNOUT_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default subAdminReducer;
