import { combineReducers } from "redux";
import authReducer from "./authReducer";
import substanceReducer from "./substanceReducer";
import manufacturerReducer from "./manufacturerReducer";
import dashboardReducer from "./dashboardReducer";
import subAdminReducer from "./subAdminReducer";
import productReducer from "./productReducer";
import goalReducer from "./goalReducer";

const initialState = {
  sidebarShow: "responsive",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  authReducer,
  changeState,
  substanceReducer,
  productReducer,
  manufacturerReducer,
  dashboardReducer,
  goalReducer,
  subAdminReducer,
});

export default rootReducer;
