// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_ERROR: "SIGNIN_SUCCESS",

  SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
  SIGNOUT_ERROR: "SIGNOUT_ERROR",

  GET_ALL_SUBSTANCES_SUCCCESS: "GET_ALL_SUBSTANCES_SUCCCESS",
  GET_ALL_SUBSTANCES_ERROR: "GET_ALL_SUBSTANCES_ERROR",

  ADD_SUBSTANCE_SUCCESS: "ADD_SUBSTANCE_SUCCESS",
  ADD_SUBSTANCE_ERROR: "ADD_SUBSTANCE_ERROR",

  UPDATE_SUBSTANCE_SUCCESS: "UPDATE_SUBSTANCE_SUCCESS",
  UPDATE_SUBSTANCE_ERROR: "UPDATE_SUBSTANCE_ERROR",

  GET_ALL_PRODUCTS_SUCCCESS: "GET_ALL_PRODUCTS_SUCCCESS",
  GET_ALL_PRODUCTS_ERROR: "GET_ALL_PRODUCTS_ERROR",

  GET_ALL_SUBSTANCE_IMAGES_SUCCCESS: "GET_ALL_SUBSTANCE_IMAGES_SUCCCESS",
  GET_ALL_SUBSTANCE_IMAGES_ERROR: "GET_ALL_SUBSTANCE_IMAGES_ERROR",

  GET_SELECTED_GOALS_SUCCESS: "GET_SELECTED_GOALS_SUCCESS",
  GET_SELECTED_GOALS_ERROR: "GET_SELECTED_GOALS_ERROR",

  CHANGE_LANGUAGE_SUCCESS: "CHANGE_LANGUAGE_SUCCESS",

  //Products

  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_ERROR: "ADD_PRODUCT_ERROR",

  GET_ALL_PRODUCT_IMAGES_SUCCCESS: "GET_ALL_PRODUCT_IMAGES_SUCCCESS",
  GET_ALL_PRODUCT_IMAGES_ERROR: "GET_ALL_PRODUCT_IMAGES_ERROR",

  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_ERROR: "UPDATE_PRODUCT_ERROR",

  //Manufacturers

  GET_ALL_MANUFACTURERS_SUCCCESS: "GET_ALL_MANUFACTURERS_SUCCCESS",
  GET_ALL_MANUFACTURERS_ERROR: "GET_ALL_MANUFACTURERS_ERROR",

  ADD_NEW_MANUFACTURER_SUCCCESS: "ADD_NEW_MANUFACTURER_SUCCCESS",
  ADD_NEW_MANUFACTURER_ERROR: "ADD_NEW_MANUFACTURER_ERROR",

  UPDATE_MANUFACTURER_SUCCCESS: "UPDATE_MANUFACTURER_SUCCCESS",
  UPDATE_MANUFACTURER_ERROR: "UPDATE_MANUFACTURER_ERROR",

  GET_DASHBOARD_SUCCCESS: "GET_DASHBOARD_SUCCCESS",
  GET_DASHBOARD_ERROR: "GET_DASHBOARD_ERROR",

  GET_LOGIN_DATA_SUCCCESS: "GET_LOGIN_DATA_SUCCCESS",
  GET_LOGIN_DATA_ERROR: "GET_LOGIN_DATA_ERROR",

  IS_LOADING: "IS_LOADING",

  //Goals

  GET_ALL_GOALS_SUCCCESS: "GET_ALL_GOALS_SUCCCESS",
  GET_ALL_GOALS_ERROR: "GET_ALL_GOALS_ERROR",

  ADD_NEW_GOAL_SUCCCESS: "ADD_NEW_GOAL_SUCCCESS",
  ADD_NEW_GOAL_ERROR: "ADD_NEW_GOAL_ERROR",

  UPDATE_GOAL_SUCCCESS: "UPDATE_GOAL_SUCCCESS",
  UPDATE_GOAL_ERROR: "UPDATE_GOAL_ERROR",

  UPDATE_GXMATRIX_SUCCCESS: "UPDATE_GXMATRIX_SUCCCESS",
  UPDATE_GXMATRIX_ERROR: "UPDATE_GXMATRIX_ERROR",

  //Level
  GET_LEVEL3_SUCCCESS: "GET_LEVEL3_SUCCCESS",
  GET_LEVEL3_ERROR: "GET_LEVEL3_ERROR",
};
