import actionTypes from "src/utils/actionTypes";

const defaultState = {
  substances: [],
  isLoading: false,
  error_message: null,
  substanceImagesFullPath: null,
  substanceImagesName: null,
};

const substanceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.GET_ALL_SUBSTANCES_SUCCCESS:
    case actionTypes.GET_ALL_SUBSTANCES_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.UPDATE_SUBSTANCE_SUCCESS:
    case actionTypes.UPDATE_SUBSTANCE_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.GET_ALL_SUBSTANCE_IMAGES_SUCCCESS:
    case actionTypes.GET_ALL_SUBSTANCE_IMAGES_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default substanceReducer;
