import actionTypes from "src/utils/actionTypes";

const defaultState = {
  manufacturers: [],
  isLoading: false,
  error_message: null,
};

const manufacturerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NEW_MANUFACTURER_SUCCCESS:
    case actionTypes.ADD_NEW_MANUFACTURER_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.GET_ALL_MANUFACTURERS_SUCCCESS:
    case actionTypes.GET_ALL_MANUFACTURERS_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.UPDATE_MANUFACTURER_SUCCCESS:
    case actionTypes.UPDATE_MANUFACTURER_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default manufacturerReducer;
