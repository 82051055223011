import actionTypes from "src/utils/actionTypes";

const defaultState = {
  user: null,
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        signInError: null,
        user: action.user,
      };

    case actionTypes.SIGNIN_ERROR:
      return {
        ...state,
        user: null,
      };

    case actionTypes.SIGNOUT_SUCCESS:
      return {
        state: defaultState,
      };

    case actionTypes.SIGNOUT_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default authReducer;
