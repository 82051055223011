import actionTypes from "src/utils/actionTypes";

const defaultState = {
  products: [],
  isLoading: false,
  error_message: null,
  productImagesName: [],
  productImagesFullPath: null,
};

const productReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PRODUCTS_SUCCCESS:
    case actionTypes.GET_ALL_PRODUCTS_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.GET_ALL_PRODUCT_IMAGES_SUCCCESS:
    case actionTypes.GET_ALL_SUBSTANCE_IMAGES_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.ADD_PRODUCT_SUCCESS:
    case actionTypes.ADD_PRODUCT_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.UPDATE_PRODUCT_SUCCESS:
    case actionTypes.UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default productReducer;
