import actionTypes from "src/utils/actionTypes";

const defaultState = {
  goals: [],
  isLoading: false,
  error_message: null,
};

const goalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_GOALS_SUCCCESS:
    case actionTypes.GET_ALL_GOALS_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.ADD_NEW_GOAL_SUCCCESS:
    case actionTypes.ADD_NEW_GOAL_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.UPDATE_GOAL_SUCCCESS:
    case actionTypes.UPDATE_GOAL_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.UPDATE_GXMATRIX_SUCCCESS:
    case actionTypes.UPDATE_GXMATRIX_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default goalReducer;
